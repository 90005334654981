.container {
  @apply flex items-center justify-center;
}

.body {
  @apply w-full;
  @apply flex items-center justify-between flex-wrap gap-1;
  @apply overflow-hidden;
}

.link {
  @apply flex-1;
  @apply block;
  @apply text-center;
  @apply text-secondary-text;
  @apply p-3;
  @apply border-b border-b-transparent;
  @apply transition-colors duration-default;
}

.link.active {
  @apply text-primary-text;
  @apply border-b-primary;
}
