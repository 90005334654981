ul li,
ol li {
  @apply list-none;
}

a {
  text-decoration: none;
  outline: none;
  transition: 0.25s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:-moz-any-link:focus,
a:-moz-focusring,
a::-moz-focus-inner,
a::-moz-focus-outer {
  outline: 0 !important;
  outline: none;
}

a:active {
  outline: none;
}

a[href^='tel:'],
a[href^='mailto:'] {
  white-space: nowrap;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  outline: none;
}
