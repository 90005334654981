html {
  --color-primary-text: 255, 255, 255;
  --color-primary-bg: 5, 11, 46;
  --color-primary-border: 255, 255, 255;

  --color-secondary-text: 166, 170, 178;
  --color-secondary-bg: 4, 9, 39;
  --color-secondary-border: 43, 48, 77;

  --color-tertiary-text: 197, 228, 243;
  --color-tertiary-bg: 25, 30, 62;
  --color-tertiary-border: 140, 143, 159;

  --color-button-primary-text: #ffffff;
  --color-button-primary-bg: #2b63f1;

  --color-button-secondary-text: #dae4fd;
  --color-button-secondary-bg: #b1c5fa33;

  --color-accent: 43, 99, 241;
  --color-warning: 230, 172, 0;
  --color-danger: 235, 67, 97;

  --highlight-card-gradient: linear-gradient(
      180deg,
      rgba(43, 99, 241, 0.2) 0%,
      rgba(43, 99, 241, 0) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));

  --warning-card-gradient: linear-gradient(
    90deg,
    rgba(230, 172, 0, 0.12) 0%,
    rgba(230, 172, 0, 0) 100%
  );

  --transition-duration-default: 100ms;
}
