.main {
  @apply flex;
  @apply bg-secondary-bg;
  @apply pt-8 sm:pt-16;
}

.body {
  @apply px-4 xl:px-0 py-8;
  @apply w-full max-w-6xl mx-auto;
  @apply flex flex-col md:flex-row;
  @apply items-center justify-between;
  @apply gap-y-3 gap-x-8;
  @apply border-t border-t-secondary;
}

.legal {
  @apply text-sm;
  @apply flex flex-col md:flex-row;
  @apply items-center justify-center;
  @apply gap-x-8 gap-y-3;
}

.legal_links {
  @apply flex items-center gap-8;
}

.legal_links > a {
  @apply text-primary-text opacity-45;
  @apply hover:opacity-100;
  @apply transition-opacity duration-default;
}

.socials {
  @apply flex items-center justify-center flex-wrap;
  @apply gap-x-7 gap-y-3;
}

.socials img {
  @apply w-5 h-5;
  @apply opacity-45 hover:opacity-100;
  @apply transition-opacity duration-default;
}
