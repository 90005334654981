.main {
  @apply mt-5;
}

.value_container {
  @apply px-3 py-3;
  @apply flex flex-col;
}

.fiat_container {
  @apply bg-primary-bg;
  @apply border border-primary/25;
}

.ever_container {
  @apply border-b border-primary/25;
}

.label {
  @apply text-sm font-bold;
  @apply text-primary-text/45;
  @apply pointer-events-none;
}

.input_with_select {
  @apply flex items-center justify-between;
  @apply gap-x-2;
}

.value_input {
  @apply w-full min-h-7;
  @apply bg-transparent text-primary-text;
  @apply text-2xl font-medium;
}

.value_input::placeholder {
  @apply text-primary-text/45;
}

.ever {
  @apply flex items-center gap-2;
}

.ever > svg {
  @apply w-5 h-5;
}

.ever > span {
  @apply text-base font-medium;
}

.recipient {
  @apply my-6;
  @apply px-3;
  @apply flex items-center justify-between;
}

.warning {
  @apply my-6;
}

.address {
  @apply font-normal;
}

.actions {
  @apply mt-6;
  @apply flex flex-col gap-3;
}

.actions button {
  @apply w-full;
}

.powered_by {
  @apply flex items-center justify-center gap-1;
  @apply text-center;
  @apply text-sm;
  @apply text-primary-text/45;
  @apply mt-4;
}

.simplex_container {
  @apply flex items-center justify-center gap-1;
  @apply text-tertiary-text;
  @apply font-medium;
}

.simplex_container > img {
  @apply w-5 h-5;
}

.validation_error {
  @apply mt-2;
  @apply text-danger;
  @apply text-sm text-center;
}
