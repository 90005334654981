.main {
  @apply flex items-center justify-between gap-x-4;
  @apply border border-secondary hover:border-tertiary;
  @apply transition-colors duration-default;
  @apply p-4;
  @apply cursor-pointer;
}

.main.fastest {
  background: var(--highlight-card-gradient);
}

.body {
  @apply flex flex-col gap-2;
  @apply flex-1;
}

.title {
  @apply flex items-center gap-2;
}

.title > span {
  @apply text-lg;
  @apply font-bold;
}

.arrow {
  @apply flex-shrink-0;
  @apply w-5 h-5;
  @apply pointer-events-none;
}

.wallets_networks {
  @apply flex items-center justify-between;
  @apply w-full;
}

.wallets_networks div {
  @apply flex flex-col gap-2;
}

.wallets_networks span {
  @apply text-sm text-primary-text/45;
}

.wallets_networks img {
  @apply h-6 w-auto;
}

.description {
  @apply text-primary-text/45;
  @apply text-sm;
}

.label {
  @apply flex items-center gap-1;
  @apply bg-accent rounded-3xl;
  @apply text-ssm font-medium;
  @apply px-[10px] py-1 mx-1;
}

.label svg {
  @apply w-[14px] h-[14px];
}
