.btn_container {
  @apply flex items-center justify-center gap-2;
  @apply cursor-pointer;
  @apply min-w-14;
}

.btn_container_arrow {
  @apply transition-transform duration-300;
}

.btn_container_arrow.open {
  @apply rotate-180;
}

.menu {
  @apply max-h-60;
  @apply min-w-24;
  @apply overflow-auto;
  @apply bg-tertiary-bg text-primary-text;
  @apply text-center;
  @apply z-50;
}

.menu_item {
  @apply bg-transparent hover:bg-primary-text/5;
  @apply flex items-center justify-center;
}

.menu_item_selected {
  @apply bg-primary-text/10;
}

.search_input_container {
  @apply px-0 pb-2 mb-1;
  @apply flex items-center justify-center;
  @apply border-b border-b-secondary;
}

.search_input {
  @apply w-full max-w-20;
  @apply bg-transparent;
  @apply text-primary-text;
  @apply px-2;
}

.search_input::placeholder {
  @apply text-sm text-primary-text/45;
}

.no_options {
  @apply text-primary-text/45;
  @apply text-sm;
  @apply pt-2 pb-1;
}

.menu {
  scrollbar-color: rgba(var(--color-primary-text), 0.45) transparent;
  scrollbar-width: thin;
}

.menu::-webkit-scrollbar {
  @apply w-1;
}

.menu::-webkit-scrollbar-thumb {
  @apply bg-primary-text/45;
}

.menu::-webkit-scrollbar-track {
  @apply bg-transparent;
}
