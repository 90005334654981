.main {
  @apply w-full;
  @apply border-l-4 border-l-warning;
  @apply text-warning;
  @apply text-sm font-medium;
  @apply p-3;
  @apply overflow-hidden;

  background: var(--warning-card-gradient);
}
