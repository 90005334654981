.main {
  @apply mt-10;
  @apply flex flex-col items-center;
  @apply text-xl font-medium;
  @apply text-center;
}

.main > svg {
  @apply w-24 h-24;
}

.actions {
  @apply mt-8;
  @apply flex flex-col gap-2;
  @apply w-full;
}

.actions a,
.actions button {
  @apply w-full;
}
