@import '@szhsin/react-menu/dist/index.css';
@import './basic.css';
@import './fonts.css';
@import './variables.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -webkit-locale: 'en';
  direction: ltr;
  unicode-bidi: isolate;
}

html,
body {
  @apply min-h-full;
  @apply w-full;
  @apply scroll-smooth;
  @apply text-primary-text;
  @apply bg-primary-bg;

  -webkit-tap-highlight-color: transparent;
}

body {
  @apply relative;
  @apply overflow-x-hidden;
  @apply opacity-100;

  font:
    400 16px/1.2 'PT Root UI',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;

  font-feature-settings:
    'tnum' on,
    'lnum' on;
}
