.main {
  @apply flex flex-col gap-8;
}

.title {
  @apply flex flex-col gap-[2px];
  @apply mb-4;
}

.title span:first-child {
  @apply text-primary-text;
  @apply font-semibold;
}

.title span:last-child {
  @apply text-primary-text/45;
  @apply text-sm;
}

.items {
  @apply grid grid-cols-1 gap-2;
}

.other {
  @apply text-sm;
  @apply text-primary-text/45;
}

.other > a {
  @apply text-tertiary-text;
}
