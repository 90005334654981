.sm {
  @apply w-6 h-6;
}

.md {
  @apply w-8 h-8;
}

.lg {
  @apply w-10 h-10;
}
