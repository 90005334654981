.main {
  @apply flex items-center justify-between;
  @apply px-2 md:px-6 py-4;
}

.logo {
  @apply w-[150px] h-auto;
}

.wallet_container {
  @apply flex items-center gap-2 sm:gap-4;
}

.ever_logo {
  @apply w-7 h-7;
}

.account_info {
  @apply flex flex-col;
}

.account {
  @apply text-sm font-medium;
}

.balance {
  @apply text-primary-text/45;
  @apply text-xs font-medium;
}

.connect_btn {
  @apply !py-2 !px-2 sm:!px-4;
  @apply !text-sm;
}

.disconnect_btn {
  @apply text-primary-text/80;
}

.disconnect_btn > svg {
  @apply w-8 h-8;
}
