.main {
  @apply bg-tertiary-bg;
  @apply max-w-form;
  @apply mt-6 sm:my-16 mx-auto;
  @apply p-5;
}

.title {
  @apply text-[2rem];
  @apply font-bold;
  @apply mb-4;
}
