.button {
  @apply flex items-center justify-center;
  @apply px-4 py-3 text-base font-medium;
}

.button:disabled {
  @apply opacity-60;
}

.primary {
  background: var(--color-button-primary-bg);
  color: var(--color-button-primary-text);
}

.secondary {
  background: var(--color-button-secondary-bg);
  color: var(--color-button-secondary-text);
}
